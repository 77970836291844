import axios, { AxiosResponse } from 'axios'
import { call, all, put, takeEvery } from 'redux-saga/effects'
import { application } from '../../../../../redux/store'
import { showLicenceReport, showPackages } from './slice'
import { LicenceTypes } from './type'

function* showLicenceReportHandler({ payload }: any) {
  try {
    const response: AxiosResponse = yield call(() =>
      axios.post(
        `${application.api}/admin/report/licence?page=${payload.page}`,
        payload
      )
    )
    yield put(showLicenceReport(response.data.data.Records))
  } catch (error: any) {
    yield put(showLicenceReport([]))
  }
}
function* showPackagesHandler({ payload }: any) {
  try {
    const response: AxiosResponse = yield call(() =>
      axios.get(`${application.api}/packages`, payload)
    )
    yield put(showPackages(response.data.data))
  } catch (error: any) {
    yield put(showPackages([]))
  }
}
export function* licenceSagas() {
  yield all([
    takeEvery(LicenceTypes.SHOW_REPORT_LICENCE, showLicenceReportHandler),
    takeEvery(LicenceTypes.SHOW_PACKAGES, showPackagesHandler),
  ])
}
