type IType = {
  SEND_RECEIPT: any
  ADD_RECEIPT: any
  EDIT_CASE: any
  SHOW_RECEIPT: any
  SHOW_UPLOADED_RECEIPT: any
  DOWNLOAD_RECEIPT: any
  DELETE_RECEIPT: any
}

export const memberAccountType: IType = {
  SEND_RECEIPT: 'SEND_RECEIPT',
  ADD_RECEIPT: 'ADD_RECEIPT',
  EDIT_CASE: 'EDIT_CASE',
  SHOW_RECEIPT: 'SHOW_RECEIPT',
  SHOW_UPLOADED_RECEIPT: 'SHOW_UPLOADED_RECEIPT',
  DOWNLOAD_RECEIPT: 'DOWNLOAD_RECEIPT',
  DELETE_RECEIPT: 'DELETE_RECEIPT',
}
