import axios, { AxiosResponse } from 'axios'
import { call, all, put, takeEvery } from 'redux-saga/effects'
import { application } from '../../../../../redux/store'
import { showMemberCall } from './slice'
import { memberCallTypes } from './type'

function* showMemberCallHandler({ payload }: any) {
  try {
    const response: AxiosResponse = yield call(() =>
      axios.get(`${application.api}/admin/user/call/all`, {
        params: {
          user_id: payload.user_id,
          page: payload.page,
          is_call: payload.status,
          start_date: payload.start_date,
          end_date: payload.end_date,
        },
      })
    )
    yield put(showMemberCall(response.data.data.Records))
  } catch (error: any) {
    yield put(showMemberCall([]))
  }
}

function* addMemberCallHandler({ payload }: any) {
  try {
    const response: AxiosResponse = yield call(() =>
      axios.post(`${application.api}/admin/user/call`, payload.data)
    )
    yield showMemberCallHandler({
      payload: { page: payload.page, user_id: payload.user_id },
    })
  } catch (error: any) {}
}

function* updateMemberCallHandler({ payload }: any) {
  try {
    const response: AxiosResponse = yield call(() =>
      axios.put(`${application.api}/admin/user/call`, payload.data)
    )
    yield showMemberCallHandler({
      payload: { page: payload.page, user_id: payload.user_id },
    })
  } catch (error: any) {}
}

function* deleteMemberCallHandler({ payload }: any) {
  try {
    const response: AxiosResponse = yield call(() =>
      axios.delete(`${application.api}/admin/user/call`, {
        data: {
          id: payload.id,
        },
      })
    )
    yield showMemberCallHandler({
      payload: { page: payload.page, user_id: payload.user_id },
    })
  } catch (error: any) {}
}

export function* memberCallSaga() {
  yield all([
    takeEvery(memberCallTypes.SHOW_MEMBER_CALL, showMemberCallHandler),
    takeEvery(memberCallTypes.ADD_MEMBER_CALL, addMemberCallHandler),
    takeEvery(memberCallTypes.UPDATE_MEMBER_CALL, updateMemberCallHandler),
    takeEvery(memberCallTypes.DELETE_MEMBER_CALL, deleteMemberCallHandler),
  ])
}
