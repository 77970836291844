import { ILogin } from '../models/models'
export type IPostLogin = {
  type: string
  payload: {
    data: ILogin
  }
}
interface IType {
  POST_LOGIN: IPostLogin['type']
}
export const LoginTypes: IType = {
  POST_LOGIN: 'POST_LOGIN',
}
