type IType = {
  SHOW_MEMBER_CALL: any
  ADD_MEMBER_CALL: any
  UPDATE_MEMBER_CALL: any
  DELETE_MEMBER_CALL: any
}

export const memberCallTypes: IType = {
  SHOW_MEMBER_CALL: 'SHOW_MEMBER_CALL',
  ADD_MEMBER_CALL: 'ADD_MEMBER_CALL',
  UPDATE_MEMBER_CALL: 'UPDATE_MEMBER_CALL',
  DELETE_MEMBER_CALL: 'DELETE_MEMBER_CALL',
}
