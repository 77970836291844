import { IRegister } from '../models/model'
export type IPostRegister = {
  type: string
  payload: {
    data: IRegister
  }
}
interface IType {
  POST_REGISTER: IPostRegister['type']
}
export const RegisterTypes: IType = {
  POST_REGISTER: 'POST_REGISTER',
}
