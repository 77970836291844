import { createSlice } from '@reduxjs/toolkit'

interface IMember {
  licences: []
  packages: []
}

const initialState: IMember = {
  licences: [],
  packages: [],
}

const licenceSLices = createSlice({
  name: 'memberCallSlice',
  initialState: initialState,
  reducers: {
    showLicenceReport: (state, action) => {
      state.licences = action.payload
    },
    showPackages: (state, action) => {
      state.packages = action.payload
    },
  },
})

export const { showLicenceReport, showPackages } = licenceSLices.actions
export default licenceSLices.reducer
