import { createSlice } from '@reduxjs/toolkit'

interface IMember {
  notifications: []
}

const initialState: IMember = {
  notifications: [],
}

const notificationSlice = createSlice({
  name: 'NotificationSlice',
  initialState: initialState,
  reducers: {
    showNotifications: (state: any, action: any) => {
      state.notifications = action.payload
    },
    updateNotifications: (state: any, action) => {
      const indexNotification: any = state.notifications.indexOf(
        (el: any) => el.ID === action.payload.ID
      )
      state.notifications[indexNotification] = action.payload
    },
    deleteNotifications: (state: any, action: any) => {
      state.notifications = state.notifications.filter(
        (el: any) => el.ID !== action.payload.id
      )
    },
  },
})

export const { showNotifications, updateNotifications, deleteNotifications } =
  notificationSlice.actions
export default notificationSlice.reducer
