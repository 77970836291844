import axios, { AxiosResponse } from 'axios'
import { takeEvery, put, call, all } from 'redux-saga/effects'
import { IPostLogin, LoginTypes } from './type'
import { application } from '../../../../../redux/store'
import { postLogin } from './slice'

function* postLoginHandler({ payload }: IPostLogin) {
  try {
    const response: AxiosResponse = yield call(() =>
      axios.post(`${application.api}/admin/login`, payload.data)
    )
    const token = response.data.data.token
    localStorage.setItem('auth', token)
    axios.defaults.headers.common['authorization'] = `Bearer ${token}`
    yield put(postLogin(true))
  } catch (error: any) {
    yield put(postLogin(false))
  }
}

export function* LoginSaga() {
  yield all([takeEvery(LoginTypes.POST_LOGIN, postLoginHandler)])
}
