type IType = {
  GET_TODOS: any
  UDPATE_TODOS: any
  ADD_TODOS: any
}

export const dashboardTodoTypes: IType = {
  GET_TODOS: 'GET_TODOS',
  UDPATE_TODOS: 'UDPATE_TODOS',
  ADD_TODOS: 'ADD_TODOS',
}
