import axios, { AxiosResponse } from 'axios'
import { all, call, put, takeEvery } from 'redux-saga/effects'
import { application } from '../../../../../redux/store'
import { getBranch, getFirms } from './slice'
import { managementFirmsType } from './type'

function* getFirmsHandler({ payload }: any) {
  try {
    const res: AxiosResponse = yield call(() =>
      axios.get(`${application.api}/show/manage/users`, {
        params: payload,
      })
    )
    yield put(getFirms(res.data.data.Records))
  } catch (error) {
  }
}
function* getFirmsBranchHandler({ payload }: any) {
  try {
    const res: AxiosResponse = yield call(() =>
      axios.get(`${application.api}/show/manage/branch?id=${payload.id}`)
    )
    yield put(getBranch(res.data.data.Records))
  } catch (error) {
    yield put(getBranch([]))
  }
}
export function* managementFirmsSaga() {
  yield all([
    takeEvery(managementFirmsType.GET_MANAGEMENT_FIRMS, getFirmsHandler),
    takeEvery(managementFirmsType.GET_MANAGEMENT_BRANCH, getFirmsBranchHandler),
  ])
}
