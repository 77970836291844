import { createSlice } from '@reduxjs/toolkit'

interface IMember {
  members: []
  is_get: boolean
}

const initialState: IMember = {
  members: [],
  is_get: false,
}

const memberListSlice = createSlice({
  name: 'magagementFirmsSlice',
  initialState: initialState,
  reducers: {
    showRemembers: (state, action) => {
      state.members = action.payload
    },
    updateIsGet: (state, action) => {
      state.is_get = action.payload
    },
    updateFalseIsGet: (state, action) => {
      state.is_get = action.payload
    },
  },
})

export const { showRemembers, updateIsGet, updateFalseIsGet } =
  memberListSlice.actions
export default memberListSlice.reducer
