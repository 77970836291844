import { createSlice } from '@reduxjs/toolkit'

interface IReport {
  smsReports: []
}

const initialState: IReport = {
  smsReports: [],
}

const smsSlices = createSlice({
  name: 'smsSlices',
  initialState: initialState,
  reducers: {
    showSmsReport: (state, action) => {
      state.smsReports = action.payload
    },
  },
})

export const { showSmsReport } = smsSlices.actions
export default smsSlices.reducer
