import { createSlice } from '@reduxjs/toolkit'

interface IMember {
  contangoReports: []
  contangoMulti: []
}

const initialState: IMember = {
  contangoReports: [],
  contangoMulti: [],
}

const contangoReportSlice = createSlice({
  name: 'contangoReportSlice',
  initialState: initialState,
  reducers: {
    showContangoReports: (state, action) => {
      state.contangoReports = action.payload
    },
    showContangoMultiReports: (state, action) => {
      state.contangoMulti = action.payload
    },
  },
})

export const { showContangoReports, showContangoMultiReports } =
  contangoReportSlice.actions
export default contangoReportSlice.reducer
