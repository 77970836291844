type IType = {
  SHOW_NOTES: any
  DELETE_NOTES: any
  ADD_NOTES: any
  UPDATE_NOTES: any
}

export const notesType: IType = {
  SHOW_NOTES: 'SHOW_NOTES',
  ADD_NOTES: 'ADD_NOTES',
  DELETE_NOTES: 'DELETE_NOTES',
  UPDATE_NOTES: 'UPDATE_NOTES',
}
