import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isRegister: false,
}
const RegisterSlice = createSlice({
  name: 'registerSlice',
  initialState: initialState,
  reducers: {
    postRegister: (state, action) => {
      state.isRegister = action.payload
    },
  },
})
export const { postRegister } = RegisterSlice.actions
export default RegisterSlice.reducer
