import axios, { AxiosResponse } from 'axios'
import moment from 'moment'
import { call, all, put, takeEvery } from 'redux-saga/effects'
import { application } from '../../../../../redux/store'
import { addNotes, deleteNotes, showNotes, updateNotes } from './slice'
import { notesType } from './type'

interface ShowNotes {
  page: number
  id: number
  payload: any
}
interface AddNotes {
  payload: {
    note: string
    id: number
  }
}
interface DeleteNotes {
  payload: {
    id: number
  }
}
function* showNotesHandler({ page, id, payload }: ShowNotes) {
  try {
    const response: AxiosResponse = yield call(() =>
      axios.get(`${application.api}/note`, {
        params: {
          page: page,
          user_id: id,
          end_date: payload.end_date,
          start_date: payload.start_date,
        },
      })
    )
    yield put(showNotes(response.data.data))
  } catch (error: any) {
    yield put(showNotes([]))
  }
}

function* addNotesHandler({ payload }: AddNotes) {
  try {
    const response: AxiosResponse = yield call(() =>
      axios.post(`${application.api}/note`, {
        user_id: payload.id,
        Note: payload.note,
      })
    )
    yield put(addNotes(response.data.data))
  } catch (error: any) {
    yield put(addNotes([]))
  }
}

function* deleteNotesHandler({ payload }: DeleteNotes) {
  try {
    const response: AxiosResponse = yield call(() =>
      axios.delete(`${application.api}/note?id=${payload.id}`)
    )
    yield put(deleteNotes(payload.id))
  } catch (error: any) {
    yield put(deleteNotes([]))
  }
}

function* updateNotesHandler({ payload }: any) {
  try {
    const response: AxiosResponse = yield call(() =>
      axios.put(`${application.api}/note`, {
        note: payload.note,
        id: payload.id,
      })
    )
    yield put(updateNotes(response.data.data))
  } catch (error: any) {
    yield put(updateNotes([]))
  }
}

export function* notesSaga() {
  yield all([
    takeEvery(notesType.SHOW_NOTES, showNotesHandler),
    takeEvery(notesType.ADD_NOTES, addNotesHandler),
    takeEvery(notesType.DELETE_NOTES, deleteNotesHandler),
    takeEvery(notesType.UPDATE_NOTES, updateNotesHandler),
  ])
}
