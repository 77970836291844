type IType = {
  SHOW_ALL_TODO: any
  SHOW_TODO_LIST: any
  ADD_TODO: any
  DELETE_TODO: any
  UPDATE_TODO: any
  UPDATE_SINGLE: any
}

export const TodoListTypes: IType = {
  SHOW_ALL_TODO: 'SHOW_ALL_TODO',
  SHOW_TODO_LIST: 'SHOW_TODO_LIST',
  ADD_TODO: 'ADD_TODO',
  DELETE_TODO: 'DELETE_TODO',
  UPDATE_TODO: 'UPDATE_TODO',
  UPDATE_SINGLE: 'UPDATE_SINGLE',
}
