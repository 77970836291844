type IType = {
  SHOW_SMS_TEMPLATE: any
  SEND_SMS: any
  SHOW_SMS: any
}

export const MemberSms: IType = {
  SHOW_SMS_TEMPLATE: 'SHOW_SMS_TEMPLATE',
  SEND_SMS: 'SEND_SMS',
  SHOW_SMS: 'SHOW_SMS',
}
