import axios, { AxiosResponse } from 'axios'
import { takeEvery, put, call, all } from 'redux-saga/effects'
import { application } from '../../../../../redux/store'
import { IPostRegister, RegisterTypes } from './type'
import { postRegister } from './slice'

function* postRegisterHandler({ payload }: IPostRegister) {
  try {
    yield call(() => axios.post(`${application.api}/admin`, payload.data))
    yield put(postRegister(true))
  } catch (error) {
    yield put(postRegister(false))
  }
}
export function* registerSaga() {
  yield all([takeEvery(RegisterTypes.POST_REGISTER, postRegisterHandler)])
}
