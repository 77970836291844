import { createSlice } from '@reduxjs/toolkit'

interface IMember {
  members_sms_templates: []
  send_sms: []
  sms: []
  is_licence: boolean
}

const initialState: IMember = {
  members_sms_templates: [],
  sms: [],
  send_sms: [],
  is_licence: false,
}

const SmsTemplateSlice = createSlice({
  name: 'memberSmsSlice',
  initialState: initialState,
  reducers: {
    /* showSmsTemplates: (state: any, action: any) => {
      state.members_sms_templates(action.payload)
    }, */
    sendSms: (state: any, action: any) => {
      state.sms.push(action.payload)
    },
    showSms: (state: any, action: any) => {
      state.sms = action.payload
    },
    checkLicence: (state: any, action: any) => {
      state.is_licence = action.payload
    },
  },
})

export const { sendSms, showSms, checkLicence } = SmsTemplateSlice.actions
export default SmsTemplateSlice.reducer
