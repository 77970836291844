type IType = {
  SHOW_NOTIFICATION_TEMPLATE: any
  UPDATE_NOTIFICATION: any
  DELETE_NOTIFICATION: any
}

export const NotificationTypes: IType = {
  SHOW_NOTIFICATION_TEMPLATE: 'SHOW_NOTIFICATION_TEMPLATE',
  UPDATE_NOTIFICATION: 'UPDATE_NOTIFICATION',
  DELETE_NOTIFICATION: 'DELETE_NOTIFICATION',
}
