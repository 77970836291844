import { createSlice } from '@reduxjs/toolkit'

interface IMember {
  tickets: []
  ticketDetails: []
}

const initialState: IMember = {
  tickets: [],
  ticketDetails: [],
}

const ticketListSlice = createSlice({
  name: 'ticketListSlice',
  initialState: initialState,
  reducers: {
    showTickets: (state, action) => {
      state.tickets = action.payload
    },
    showTicketDetail: (state, action) => {
      state.ticketDetails = action.payload
    },
  },
})

export const { showTickets, showTicketDetail } = ticketListSlice.actions
export default ticketListSlice.reducer
