import { createSlice } from '@reduxjs/toolkit'

interface IMember {
  members_update: []
  employees: []
  last_note: []
  last_call: []
  sms: boolean
  deleted: boolean
}

const initialState: IMember = {
  members_update: [],
  employees: [],
  sms: false,
  last_note: [],
  last_call: [],
  deleted: false,
}

const memberUpdateSlice = createSlice({
  name: 'memberUpdateSlice',
  initialState: initialState,
  reducers: {
    showMembers: (state, action) => {
      state.members_update = action.payload
    },
    deleteMembers: (state, action) => {
      state.deleted = action.payload
    },
    updateMembers: (state, action) => {
      state.members_update = action.payload
    },
    listEmployee: (state, action) => {
      state.employees = action.payload
    },
    deleteSmsMembers: (state, action) => {
      state.sms = action.payload
    },
    lastCall: (state, action) => {
      state.last_call = action.payload
    },
    lastNote: (state, action) => {
      state.last_note = action.payload
    },
  },
})

export const {
  showMembers,
  updateMembers,
  listEmployee,
  deleteMembers,
  deleteSmsMembers,
  lastNote,
  lastCall,
} = memberUpdateSlice.actions
export default memberUpdateSlice.reducer
