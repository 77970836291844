import axios, { AxiosResponse } from 'axios'
import { call, all, put, takeEvery } from 'redux-saga/effects'
import { application } from '../../../../../redux/store'
import { showCollectionReports } from './slice'
import { CollectionReportTypes } from './type'

function* showCollectionReportsHandler({ payload }: any) {
  try {
    const response: AxiosResponse = yield call(() =>
      axios.post(`${application.api}/admin/report/collection`, payload)
    )
    yield put(showCollectionReports(response.data.data.Records))
  } catch (error: any) {
    yield put(showCollectionReports([]))
  }
}
export function* collectionReportSagas() {
  yield all([
    takeEvery(CollectionReportTypes.SHOW_COLLECTION_REPORT, showCollectionReportsHandler),
  ])
}
