import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  todos: [],
}

const dashboardTodoSlice = createSlice({
  name: 'dashboardTodoSlice',
  initialState: initialState,
  reducers: {
    getTodos: (state: any, action: any) => {
      state.todos = action.payload
    },
    addTodos: (state: any, action: any) => {
      state.todos = [...state.todos, action.payload]
    },
    updateTodos: (state: any, action: any) => {
      const Index: any = state.todos.findIndex(
        (el: any) => el.id === action.payload.id
      )
      state.todos[Index] = action.payload
    },
  },
})

export const { getTodos, addTodos, updateTodos } = dashboardTodoSlice.actions
export default dashboardTodoSlice.reducer
