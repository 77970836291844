import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  employee: [],
}

const employeeSlice = createSlice({
  name: 'employeeSlice',
  initialState: initialState,
  reducers: {
    getEmployee: (state: any, action: any) => {
      state.employee = action.payload
    },
    addEmployee: (state: any, action: any) => {
      state.employee.unshift(action.payload)
    },
    updateEmployee: (state: any, action: any) => {
      const indexEmployee: any = state.employee.findIndex(
        (el: any) => el.id === action.payload.id
      )
      state.employee[indexEmployee] = action.payload
    },
    deleteEmployee: (state: any, action: any) => {
      state.employee = state.employee.filter(
        (el: any) => el.id !== action.payload
      )
    },
  },
})

export const { getEmployee, addEmployee, updateEmployee, deleteEmployee } =
  employeeSlice.actions
export default employeeSlice.reducer
