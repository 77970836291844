type IType = {
  SHOW_MEMBER: any
  UPDATE_MEMBER: any
  LIST_EMPLOYEE: any
  DELETE_MEMBER: any
  DELETE_SMS: any
  LAST_INFOMATION: any
  CALL_MEMBER: any
}

export const MemberUpdate: IType = {
  SHOW_MEMBER: 'SHOW_MEMBER',
  UPDATE_MEMBER: 'UPDATE_MEMBER',
  LIST_EMPLOYEE: 'LIST_EMPLOYEE',
  DELETE_MEMBER: 'DELETE_MEMBER',
  DELETE_SMS: 'DELETE_SMS',
  LAST_INFOMATION: 'LAST_NOTE',
  CALL_MEMBER: 'CALL_MEMBER',
}
