import axios, { AxiosResponse } from 'axios'
import { call, all, put, takeEvery } from 'redux-saga/effects'
import { application } from '../../../../../redux/store'
import { showSmsReport } from './slice'
import { SmsReportType } from './type'

function* smsReportHandler({ payload }: any) {
  try {
    const response: AxiosResponse = yield call(() =>
      axios.post(
        `${application.api}/admin/report/sms?page=${payload.page}`,
        payload
      )
    )
    yield put(showSmsReport(response.data.data.Records))
  } catch (error: any) {
    yield put(showSmsReport([]))
  }
}
export function* smsSagas() {
  yield all([takeEvery(SmsReportType.SHOW_REPORT_SMS, smsReportHandler)])
}
