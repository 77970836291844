import axios, { AxiosResponse } from 'axios'
import { all, call, put, takeEvery } from 'redux-saga/effects'
import { numberReplace } from 'src/functions/Number/numberReplace'
import { application } from '../../../../../redux/store'
import {
  addEmployee,
  deleteEmployee,
  getEmployee,
  updateEmployee,
} from './slice'
import { employeeTypes } from './type'

function* getEmployeeHandler({ page }: any) {
  try {
    const res: AxiosResponse = yield call(() =>
      axios.get(`${application.api}/admin?page=${page}`)
    )
    yield put(getEmployee(res.data.data))
  } catch (error) {
    yield put(getEmployee([]))
  }
}

function* addEmployeeHandler({ payload }: any) {
  try {
    const res: AxiosResponse = yield call(() =>
      axios.post(`${application.api}/admin`, {
        name: payload.name,
        surname: payload.surname,
        email: payload.email,
        central_interior: payload.employee_code,
        phone: numberReplace(payload.phone),
        password: '123123',
        permission_id: payload.permission_id,
      })
    )
    yield put(addEmployee(res.data.data))
  } catch (error) {}
}

function* updateEmployeeHandler({ payload }: any) {
  try {
    const res: AxiosResponse = yield call(() =>
      axios.put(`${application.api}/admin`, {
        id: payload.id,
        name: payload.name,
        surname: payload.surname,
        email: payload.email,
        central_interior: payload.employee_code,
        phone: numberReplace(payload.phone),
        password: payload.password,
        permission_id: payload.permission_id,
      })
    )
    yield put(updateEmployee(res.data.data))
  } catch (error) {
    yield put(updateEmployee([]))
  }
}

function* deleteEmployeeHandler({ payload }: any) {
  try {
    const res: AxiosResponse = yield call(() =>
      axios.delete(`${application.api}/admin?id=${payload.id}`)
    )
    yield put(deleteEmployee(payload.id))
  } catch (error) {
    yield put(deleteEmployee([]))
  }
}

export function* employeeSagas() {
  yield all([
    takeEvery(employeeTypes.SHOW_EMPLOYEE, getEmployeeHandler),
    takeEvery(employeeTypes.ADD_EMPLOYEE, addEmployeeHandler),
    takeEvery(employeeTypes.UPDATE_EMPLOYEE, updateEmployeeHandler),
    takeEvery(employeeTypes.DELETE_EMPLOYEE, deleteEmployeeHandler),
  ])
}
