import { createSlice } from '@reduxjs/toolkit'

interface IMember {
  send_receipt: []
  show_receipt: []
  show_uploaded_receipt: []
  download_receipt: []
  add_receipt: []
}

const initialState: IMember = {
  send_receipt: [],
  add_receipt: [],
  show_receipt: [],
  show_uploaded_receipt: [],
  download_receipt: [],
}

const memberAccountSlice = createSlice({
  name: 'memberAccountSlice',
  initialState: initialState,
  reducers: {
    sendReceipt: (state: any, action: any) => {
      state.send_receipt = action.payload
    },
    addReceipt: (state: any, action: any) => {
      state.add_receipt = action.payload
    },
    updateReceipt: (state: any, action: any) => {
      const indexReceipt: any = state.add_receipt.findIndex(
        (el: any) => el.id === action.payload.id
      )
      state.add_receipt[indexReceipt] = action.payload
    },
    showReceipt: (state: any, action: any) => {
      state.show_receipt = action.payload
    },
    showUploadedReceipt: (state: any, action: any) => {
      state.show_uploaded_receipt = action.payload
    },
    downloadReceipt: (state: any, action: any) => {
      state.download_receipt = action.payload
    },
    deleteReceipt: (state: any, action: any) => {
      state.show_uploaded_receipt = state.documents.filter(
        (el: any) => el.id !== action.payload
      )
    },
  },
})

export const {
  sendReceipt,
  addReceipt,
  updateReceipt,
  showReceipt,
  showUploadedReceipt,
  downloadReceipt,
  deleteReceipt,
} = memberAccountSlice.actions
export default memberAccountSlice.reducer
