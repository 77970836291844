import { createSlice } from '@reduxjs/toolkit'

interface IMember {
  collectionReports: []
}

const initialState: IMember = {
  collectionReports: [],
}

const collectionReportSlice = createSlice({
  name: 'collectionReportSlice',
  initialState: initialState,
  reducers: {
    showCollectionReports: (state, action) => {
      state.collectionReports = action.payload
    },
  },
})

export const { showCollectionReports } = collectionReportSlice.actions
export default collectionReportSlice.reducer
