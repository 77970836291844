import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  phone: '',
  password: '',
  isLogin: false,
}
const LoginSlice = createSlice({
  name: 'LoginSlice',
  initialState: initialState,
  reducers: {
    postLogin: (state, action) => {
      state.isLogin = action.payload
    },
  },
})
export const { postLogin } = LoginSlice.actions
export default LoginSlice.reducer
