type IType = {
  SHOW_TICKETS: any
  SHOW_TICKETS_DETAIL: any
  UPDATE_TICKETS: any
  SEND_TICKET_MESSAGE: any
  DELETE_TICKET: any
}

export const ticketListTypes: IType = {
  SHOW_TICKETS: 'SHOW_TICKETS',
  UPDATE_TICKETS: 'UPDATE_TICKETS',
  SHOW_TICKETS_DETAIL: 'SHOW_TICKETS_DETAIL',
  SEND_TICKET_MESSAGE: 'SEND_TICKET_MESSAGE',
  DELETE_TICKET: 'DELETE_TICKET',
}
