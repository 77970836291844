// ** Reducers Imports
import { combineReducers } from '@reduxjs/toolkit'
import navbar from './navbar'
import layout from './layout'
import LoginSlice from '../views/pages/authentication/Login/store/slice'
import managementFirmsSlice from '../views/pages/members/managementFirms/store/slice'
import memberListSlice from '../views/pages/members/memberList/store/slice'
import registerSlice from '../views/pages/authentication/Register/store/slice'
import notesSlice from '../views/pages/members/memberNotes/store/slice'
import memberUpdateSlice from '../views/pages/members/memberUpdate/store/slice'
import employeeSlice from '../views/pages/employee/employeeList/store/slice'
import memberCallSlice from 'src/views/pages/members/memberCall/store/slice'
import ticketListSlice from '../views/pages/support/supportIncoming/store/slice'
import SmsTemplateSlice from '../views/pages/members/memberSms/store/slice'
import memberAccountSlice from '../views/pages/members/memberAccount/store/slice'
import licenceSlice from 'src/views/pages/report/licenseReports/store/slice'
import smsSlice from 'src/views/pages/report/smsReports/store/slice'
import notificationSlice from 'src/views/pages/notifications/store/slice'
import dashboardTodoSlice from 'src/views/dashboard/store/slice'
import TodoListsSlice from 'src/views/dashboard/ecommerce/containers/todo/store/slice'
import collectionReportSlice from 'src/views/pages/report/collectionReports/store/slice'
import contangoReportSlice from 'src/views/pages/report/contangoReports/store/slice'
const rootReducer = combineReducers({
  navbar,
  layout,
  LoginSlice,
  managementFirmsSlice,
  memberListSlice,
  registerSlice,
  notesSlice,
  memberUpdateSlice,
  employeeSlice,
  memberCallSlice,
  ticketListSlice,
  SmsTemplateSlice,
  memberAccountSlice,
  licenceSlice,
  smsSlice,
  notificationSlice,
  dashboardTodoSlice,
  TodoListsSlice,
  collectionReportSlice,
  contangoReportSlice,
})

export default rootReducer
