import axios, { AxiosResponse } from 'axios'
import toast from 'react-hot-toast'
import { call, all, put, takeEvery } from 'redux-saga/effects'
import { application } from '../../../../../redux/store'
import { checkLicence, sendSms, showSms } from './slice'
import { MemberSms } from './type'

function* showSmsHandler({ payload }: any) {
  try {
    const res: AxiosResponse = yield call(() =>
      axios.get(`${application.api}/show/user/sms/${payload.user_id}`)
    )
    yield put(showSms(res.data.data))
  } catch (error) {
    yield put(showSms([]))
  }
}

function* sendSmsHandler({ payload }: any) {
  try {
    const res: AxiosResponse = yield call(() =>
      axios.post(`${application.api}/admin/sms`, {
        user_id: payload?.user_id,
        message: payload?.message,
        template_id: payload?.template_id,
      })
    )
    yield showSmsHandler({ payload: { user_id: payload.user_id } })
  } catch (error) {
    yield put(sendSms([]))
  }
}

export function* memberSmsSaga() {
  yield all([
    takeEvery(MemberSms.SEND_SMS, sendSmsHandler),
    takeEvery(MemberSms.SHOW_SMS, showSmsHandler),
  ])
}
