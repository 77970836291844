import { createSlice } from '@reduxjs/toolkit'

interface IMember {
  memberCalls: []
}

const initialState: IMember = {
  memberCalls: [],
}

const memberCallSlice = createSlice({
  name: 'memberCallSlice',
  initialState: initialState,
  reducers: {
    showMemberCall: (state, action) => {
      state.memberCalls = action.payload
    },
  },
})

export const { showMemberCall } = memberCallSlice.actions
export default memberCallSlice.reducer
