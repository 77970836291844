import { IShowMemberList } from '../models/model'

interface IType {
  SHOW_REMEMBERS: IShowMemberList['type']
  SEARCH_REMEMBERS: any
  GET_UPDATE: any
}

export const memberListType: IType = {
  SHOW_REMEMBERS: 'SHOW_REMEMBERS',
  SEARCH_REMEMBERS: 'SEARCH_REMEMBERS',
  GET_UPDATE: 'GET_UPDATE',
}
