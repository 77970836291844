type IType = {
  SHOW_EMPLOYEE: any
  ADD_EMPLOYEE: any
  DELETE_EMPLOYEE: any
  UPDATE_EMPLOYEE: any
}

export const employeeTypes: IType = {
  SHOW_EMPLOYEE: 'SHOW_EMPLOYEE',
  ADD_EMPLOYEE: 'ADD_EMPLOYEE',
  DELETE_EMPLOYEE: 'DELETE_EMPLOYEE',
  UPDATE_EMPLOYEE: 'UPDATE_EMPLOYEE',
}
