import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  firms: [],
  branch: [],
}

const managementFirmsSlice = createSlice({
  name: 'magagementFirmsSlice',
  initialState: initialState,
  reducers: {
    getFirms: (state, action) => {
      state.firms = action.payload
    },
    getBranch: (state, action) => {
      state.branch = action.payload
    },
  },
})

export const { getFirms, getBranch } = managementFirmsSlice.actions
export default managementFirmsSlice.reducer
